import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import DynamicMediaCTA from 'components/DynamicMediaCTA';
import GridEmailCapture from 'components/EmailCapture/GridEmailCapture';
import Carousel from 'components/LandingPage/Carousel';
import FullWidthVideo from 'components/LandingPage/FullWidthVideo';
import MediaCTA from 'components/MediaCTA';
import isBrowser from 'utils/is-browser';
import styles from './ContentGrid.module.scss';

export function ContentGrid({
  main_hero: mainHero,
  second_hero: secondHero,
  center_first: centerFirst,
  center_second: centerSecond,
  center_third: centerThird,
  center_fourth: centerFourth,
  center_lower: centerLower,
  right_large: rightLarge,
  right_medium: rightMedium,
  right_small: rightSmall,
  left_large: leftLarge,
  left_medium: leftMedium,
  left_small: leftSmall,
  email_capture: emailCapture,
  productMap = {},
}) {
  const showNewsletter = useSelector((state) => state.featureFlags.newsletter);
  const userInfo = useSelector((state) => state.user.userInfo);
  const [showEmailCapture, setShowEmailCapture] = useState(false);
  const [gridSuccessSignUp, setGridSuccessSignUp] = useState(false);

  const emailContent = { ...emailCapture?.[0] };
  const heroContent = { ...mainHero?.[0] };
  const secondHeroContent = { ...secondHero?.[0] };

  const fullWidthSizes = '(min-width: 992px) 50vw, 100vw';

  const halfWidthSizes = '(min-width: 992px) 25vw, 50vw';

  useEffect(() => {
    if (isBrowser) {
      const onResize = () => {
        const body = document.querySelector('body');
        const grid = document.querySelector(classNames('.' + styles.grid));
        if (!body || !grid) {
          return;
        }

        if (window.innerWidth > body.clientWidth + 5) {
          grid.setAttribute(
            'style',
            '--scroll-bar: ' + (window.innerWidth - body.clientWidth) + 'px'
          );
        } else {
          grid.removeAttribute('style');
        }
      };
      onResize();
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }
  });

  useEffect(() => {
    const { klaviyoProperties, isEnrolledMarketing } = userInfo ?? {};
    /* eslint-disable camelcase */
    const { klaviyo_custom_property } = emailContent ?? {};
    if (showNewsletter) {
      setShowEmailCapture(
        !isEnrolledMarketing ||
          gridSuccessSignUp ||
          (klaviyo_custom_property &&
            !klaviyoProperties?.[klaviyo_custom_property])
      );
      /* eslint-enable camelcase */
    } else {
      setShowEmailCapture(false);
    }
  }, [
    emailContent?.klaviyo_custom_property,
    showNewsletter,
    gridSuccessSignUp,
    userInfo?.isEnrolledMarketing,
    userInfo?.klaviyoProperties,
  ]);

  const renderMediaCta = ({
    cmsData,
    productMap = {},
    id,
    index,
    priority,
    sizes,
  }) => {
    const isDynamicMediaCta =
      cmsData[0]?._content_type_uid === 'dynamic_media_cta';
    const productContent = productMap[cmsData?.[0]?.dynamic_type]?.[index];

    return isDynamicMediaCta && productContent ? (
      <DynamicMediaCTA
        priority={priority}
        id={id}
        cmsData={cmsData}
        productContent={productContent}
        sizes={sizes}
      />
    ) : (
      <MediaCTA priority={priority} id={id} cmsData={cmsData} sizes={sizes} />
    );
  };

  return (
    <div className={classNames(styles.grid)}>
      <div className={classNames(styles.grid_item, styles.grid_item_main_hero)}>
        <div className={classNames(styles.video_container)}>
          {heroContent &&
            (heroContent._content_type_uid === 'media_cta' ? (
              <MediaCTA
                priority
                id="mainHero"
                cmsData={mainHero}
                sizes={fullWidthSizes}
              />
            ) : heroContent._content_type_uid === 'carousel' ? (
              <Carousel isGridCarousel id="mainHero" {...heroContent} />
            ) : (
              <FullWidthVideo {...heroContent} />
            ))}
        </div>
      </div>
      <div
        className={classNames(styles.grid_item, styles.grid_item_center_first)}
      >
        {renderMediaCta({
          cmsData: centerFirst,
          productMap,
          id: 'centerFirst',
          index: 3,
          priority: true,
          sizes: halfWidthSizes,
        })}
      </div>
      <div
        className={classNames(styles.grid_item, styles.grid_item_center_second)}
      >
        {renderMediaCta({
          cmsData: centerSecond,
          productMap,
          id: 'centerSecond',
          index: 4,
          priority: true,
          sizes: halfWidthSizes,
        })}
      </div>
      <div
        className={classNames(styles.grid_item, styles.grid_item_center_lower)}
      >
        <MediaCTA
          priority
          id="centerLower"
          cmsData={centerLower}
          sizes={fullWidthSizes}
        />
      </div>
      <div
        className={classNames({
          [styles.grid_item_left_large_no_email_form]: !showEmailCapture,
          [styles.grid_item]: showEmailCapture,
          [styles.grid_item_left_large]: showEmailCapture,
        })}
      >
        {renderMediaCta({
          cmsData: leftLarge,
          productMap,
          id: 'leftLarge',
          index: 5,
          priority: false,
          sizes: halfWidthSizes,
        })}
      </div>
      <div
        className={classNames(styles.grid_item, styles.grid_item_left_medium)}
      >
        {renderMediaCta({
          cmsData: leftMedium,
          productMap,
          id: 'leftMedium',
          index: 0,
          priority: true,
          sizes: halfWidthSizes,
        })}
      </div>
      <div
        className={classNames(styles.grid_item, styles.grid_item_left_small)}
      >
        {renderMediaCta({
          cmsData: leftSmall,
          productMap,
          id: 'leftSmall',
          index: 1,
          priority: true,
          sizes: halfWidthSizes,
        })}
      </div>
      <div
        className={classNames(styles.grid_item, styles.grid_item_right_large)}
      >
        {renderMediaCta({
          cmsData: rightLarge,
          productMap,
          id: 'rightLarge',
          index: 2,
          priority: true,
          sizes: halfWidthSizes,
        })}
      </div>
      <div
        className={classNames({
          [styles.grid_item_right_medium_no_email_form]: !showEmailCapture,
          [styles.grid_item]: showEmailCapture,
          [styles.grid_item_right_medium]: showEmailCapture,
        })}
      >
        {renderMediaCta({
          cmsData: rightMedium,
          productMap,
          id: 'rightMedium',
          index: 6,
          priority: false,
          sizes: halfWidthSizes,
        })}
      </div>
      <div
        className={classNames({
          [styles.grid_item_right_small_no_email_form]: !showEmailCapture,
          [styles.grid_item]: showEmailCapture,
          [styles.grid_item_right_small]: showEmailCapture,
        })}
      >
        {renderMediaCta({
          cmsData: rightSmall,
          productMap,
          id: 'rightSmall',
          index: 7,
          priority: false,
          sizes: halfWidthSizes,
        })}
      </div>
      <div
        className={classNames(styles.grid_item, styles.grid_item_second_hero)}
      >
        <div className={classNames(styles.video_container)}>
          {secondHeroContent &&
            (secondHeroContent._content_type_uid === 'media_cta' ? (
              <MediaCTA
                // priority below the fold on mobile
                id="secondHero"
                cmsData={secondHero}
                sizes={fullWidthSizes}
              />
            ) : secondHeroContent._content_type_uid === 'carousel' ? (
              <Carousel isGridCarousel id="secondHero" {...secondHeroContent} />
            ) : (
              <FullWidthVideo {...secondHeroContent} />
            ))}
        </div>
      </div>
      <div
        className={classNames({
          [styles.grid_item_center_third_no_email_form]: !showEmailCapture,
          [styles.grid_item]: showEmailCapture,
          [styles.grid_item_center_third]: showEmailCapture,
        })}
      >
        {renderMediaCta({
          cmsData: centerThird,
          productMap,
          id: 'centerThird',
          index: 8,
          priority: false,
          sizes: halfWidthSizes,
        })}
      </div>
      <div
        className={classNames({
          [styles.grid_item_center_fourth_no_email_form]: !showEmailCapture,
          [styles.grid_item]: showEmailCapture,
          [styles.grid_item_center_fourth]: showEmailCapture,
        })}
      >
        {renderMediaCta({
          cmsData: centerFourth,
          productMap,
          id: 'centerFourth',
          index: 9,
          priority: false,
          sizes: halfWidthSizes,
        })}
      </div>
      {showEmailCapture && (
        <div
          className={classNames(
            styles.grid_item,
            styles.grid_item_email_capture
          )}
        >
          <div className={classNames(styles.email_capture_container)}>
            <GridEmailCapture
              cmsData={emailContent}
              setGridSuccessSignUp={setGridSuccessSignUp}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ContentGrid;
